//Import you university logo with the name uni_logo.png or configure here
//import uni_logo from './images/uni_logo_NOVA.png';
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import { University_ID } from "./Config";
import {UrlMahara} from './Url';

//Switch Case that tests the imported University_ID and sets the current import for uni_logo
let uni_logo;
switch (Number(University_ID)) {
  case 299:
    uni_logo = require("./images/uni_logo_NOVA.png");
    break;
  case 300:
    uni_logo = require("./images/uni_logo_ULISBOA.png");
    break;
  case 533:
    uni_logo = require("./images/uni_logo_PLATA.png");
    break;
  default:
    //Missing University Logo
    uni_logo = require("./images/uni_logo_NOIMAGE.jpg");
    break; 
}

//Set up the secondary connection
const maharaServer = axios.create({
  baseURL: UrlMahara,
});

const localServer = axios.create({
  baseURL: "http://localhost:4000",
});

const AcademicCertificationContainer = (props) => {
  const [bearerToken, setBearerToken] = useState("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMSIsInVzZXJfZW1haWwiOiJ1c2VyMUBnbWFpbC5jb20iLCJjb21wYW55X2lkIjoxfQ.hV1MQI4OKRguWsd11uVqWzaAz4h9v0zv_33U-ioKza8");
 
  const universityId = props.universityId;
  const [universityName, setUniversityName] = useState("");  
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificateId, setSelectedCertificateId] = useState("");
  const [experienceCertificates, setExperienceCertificates] = useState([]);
  const [selectedExperienceCertificateId, setSelectedExperienceCertificateId] = useState("");
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses2, setCourses2] = useState([]);

  const [degreeCertificates, setDegreeCertificates] = useState([]);
  const [selectedDegreeCertificateId, setSelectedDegreeCertificateId] = useState("");
  const [selectedDegreeType, setSelectedDegreeType] = useState('');

  //I need a new state to store the degree types that are constants
  const degreeTypes = [
    { code: 1, name: "Associate" },
    { code: 2, name: "Bachelor" },
    { code: 3, name: "Master" },
    { code: 4, name: "Doctorate" },
    { code: 5, name: "Postgraduate" },
    { code: 6, name: "Honorary" },
    { code: 7, name: "Other" },
  ];
 
  useEffect(() => {     
    fetchUniName(universityId);    
    //fetchUniUsers(universityId);
    fetchUniUsersandExternals(universityId);
    fetchLocalInfo(universityId);
  }, [universityId]);

  const fetchLocalInfo = async (universityId) => {
    try {      
      const response = await axios.get(`/get-uni-info/${universityId}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });

      if(response.data.availableModules.length === 0) {
        console.log("No modules found for this university");
        setCourses([]);
        return;
      }
      else {        
        console.log("Info Local", response.data);
        console.log("Available Modules", response.data.availableModules);
        setCourses(response.data.availableModules);
      }
    } catch (error) {
      console.error("Error fetching basic setup local info:", error);
    }
  };

  const fetchUniName = async (uniId) => {
    try {      
      const response = await axios.get(`/get-uni-name/${uniId}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
      console.log("Uni Name Javali: " + response.data);
      setUniversityName(response.data);
    } catch (error) {
      console.error("Error fetching universities:", error);
    }
  };

  const fetchUniUsersandExternals = async (universityId) => { //Need to add the external users (eportefolio requests) to the FE
    try {
      const response = await axios.get(`/get-uni-users/${universityId}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
      const usersData = response.data;
      if (usersData.length === 0) {
        console.log("No users found for this university");
        setUsers([]);
        return;
      }
      else {

      //request externals
      const responseExternals = await axios.get(`/eportefolio-requests/external-users/${universityId}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
      const usersExternalsData = responseExternals.data;
      console.log("Externals: ", usersExternalsData);

      //join both objects
      const usersDataWithExternals = usersData.concat(usersExternalsData);
      console.log("Users with Externals: ", usersDataWithExternals);

      // Add internal_id to each user object and maintain order
        const usersWithInternalId = usersDataWithExternals.map((user, index) => ({
          ...user,
          internal_id: index + 1,
        }));

        console.log("Users with Internal IDs:", usersWithInternalId);
        setUsers(usersWithInternalId);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchUniUsers = async (universityId) => { //Need to add the external users (eportefolio requests) to the FE
    try {
      const response = await axios.get(`/get-uni-users/${universityId}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
      const usersData = response.data;
      if (usersData.length === 0) {
        console.log("No users found for this university");
        setUsers([]);
        return;
      }
      else {
      // Add internal_id to each user object and maintain order
        const usersWithInternalId = usersData.map((user, index) => ({
          ...user,
          internal_id: index + 1,
        }));

        console.log("Users with Internal IDs:", usersWithInternalId);
        setUsers(usersWithInternalId);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchCertificates = async (userId) => {
    // Here is should make a request to url base changed on url to /academicCertificates/byUser/:userid
    try {
      maharaServer.get(`/academicCertificates/byUser/${userId}`)
        .then(response => {
          // Handle response
          console.log("Mahara Academic Response", response.data);
          setCertificates(response.data);
        });           
    } catch (error) {
      console.error("Error fetching certificates:", error);
    }
  };

 
  const fetchExperienceCertificates = async (userId) => {
    //Make request to /experienceCertificates/byUser/:userid
    try {
      maharaServer.get(`/experienceCertificates/byUser/${userId}`)
        .then(response => {
          // Handle response
          console.log("Mahara Experience Response", response.data);
          setExperienceCertificates(response.data);
        });          
    } catch (error) {
      console.error("Error fetching experience certificates:", error);
    }
  };

  const fetchDegreeCertificates = async (userId) => { 
    // Here is should make a request to url base changed on url to /degreeCertificates/byUser/:userid
     try {
      maharaServer.get(`/degreeCertificates/byUser/${userId}`)
        .then(response => {
          // Handle response
          console.log("Mahara Degree Response", response.data);
          setDegreeCertificates(response.data);
        });           
    } catch (error) {
      console.error("Error fetching degree certificates:", error);
    } 
    console.log("Fetching Degree Certificates");
  };

  const fetchCoursesTarget = async (universityId) => {
    try {
      const response = await axios.get(
        `/courses/byUniversity/${universityId}`, {
          headers: {
            'Authorization': `Bearer ${bearerToken}`
          }
        });
      setCourses2(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  //handlers
  const handleUserClick = async (userId) => {
    if (userId === selectedUserId) {
      setSelectedUserId("");
      setCertificates([]);  
      setExperienceCertificates([]);    
      setDegreeCertificates([]);
    } else {
      setSelectedUserId(userId);
      fetchExperienceCertificates(userId); 
      fetchDegreeCertificates(userId);
      try {     
        maharaServer.get(`/academicCertificates/byUser/${userId}`)
        .then(response => {
          // Handle response
          console.log("Mahara Academic Response", response.data);
          setCertificates(response.data);
        });                
      } catch (error) {
        console.error("Error fetching certificates:", error);
      }
    }
  };

  const handleCertificateClick = async (
    selectedUniversityId,
    certificateId
  ) => {
    if (certificateId === selectedCertificateId) {
      setSelectedCertificateId("");
    } else {
      setSelectedCertificateId(certificateId);
      try {        
        fetchCertificates(selectedUserId);
        //fetchEquivalences(selectedUniversityId, certificateId);
      } catch (error) {
        console.error("Error fetching equivalences:", error);
      }
    }
  };

  const handleExperienceCertificateClick = async (
    selectedUniversityId,
    experienceCertificateId
  ) => {
    if (experienceCertificateId === selectedExperienceCertificateId) {
      setSelectedExperienceCertificateId("");
    } else {
      //console.log("Experience Certificate ID: " + experienceCertificateId);
      setSelectedExperienceCertificateId(experienceCertificateId);
      try {        
        fetchExperienceCertificates(selectedUserId);
        //console.log("Experience Certificate ID: " + selectedExperienceCertificateId);
        //fetchEquivalences(selectedUniversityId, certificateId);
      } catch (error) {
        console.error("Error fetching experience certificates:", error);
      }
    }
  };

  const handleDegreeCertificateClick = async (
    selectedUniversityId,
    degreeCertificateId
  ) => {
    if (degreeCertificateId === selectedDegreeCertificateId) {
      setSelectedDegreeCertificateId("");
    } else {
      setSelectedDegreeCertificateId(degreeCertificateId);
      try {        
        fetchDegreeCertificates(selectedUserId);       
      } catch (error) {
        console.error("Error fetching degree certificates:", error);
      }
    }
  };

  const handleInsertCertificate = async (event) => { 
      event.preventDefault(); 
      const grade = event.target.elements.grade.value;   
      const courseName = selectedCourse;     
      const CompleteCourse = courses.find((course) => course.name === courseName);
      const courseId = CompleteCourse.id;

      const insertCertificateResponse = await maharaServer.post('/store-academic-certificate', {        
        userId: selectedUserId,
        universityId,
        courseId,
        grade,
      },
      {
        headers: {
          'Authorization': `Bearer ${bearerToken}`, // Include the Bearer Token
          'Content-Type': 'application/json', 
        },
      }
    ); 
    fetchCertificates(selectedUserId);  
    console.log("Inserted Academic Certificate and updated List");    
  };


  const handleInsertExperienceCertificate = async (event) => {

      event.preventDefault();
      const Description = event.target.elements.description.value;     
        
      const insertExperienceCertificateResponse = await maharaServer.post('/store-experience-certificate', {       
        userId: selectedUserId,
        universityId,
        description: Description,      
      }, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`, // Include the Bearer Token
          'Content-Type': 'application/json',
        },
      });
      fetchExperienceCertificates(selectedUserId);    
      event.target.reset();       
  };

  //TODO DEGREE: Working here
  const handleInsertDegreeCertificate = async (event) => { 
    event.preventDefault(); 
    const grade = event.target.elements.grade.value;
    const name = event.target.elements.name.value;
    const degreeType = selectedDegreeType;  

    //change local to Mahara
    const insertCertificateResponse = await maharaServer.post('/store-degree-certificate', {        
      ID_YachaY_User: selectedUserId,
      ID_YachaY_University: universityId,      
      Grade: grade,
      Name: name,
      DegreeType: degreeType
    },
    {
      headers: {
        'Authorization': `Bearer ${bearerToken}`, // Include the Bearer Token
        'Content-Type': 'application/json', 
      },
    }
  ); 
  fetchDegreeCertificates(selectedUserId);   
  console.log("Inserted Degree Certificate and updated List");    
  };

  const testStatus = async (certificateId) => {
    try {
         //I test the certificate id for 'invalid' -> return pop-up can't insert equivalence on invalid certificate
      const responseCertificateStatus = await axios.get(`/Certificate/Status/${certificateId}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
      const CertificateStatus = responseCertificateStatus.data.Status;
      console.log("Certificate Status:" + CertificateStatus);
      return CertificateStatus;
    } catch (error) {
      console.error("Error fetching certificate status:", error);
    }
  };

  const invalidateResource = async (resourceType, ResourceId) => {
    try {
      switch(resourceType) {
        case "certificate":         
          const CertificateStatus = await testStatus(ResourceId);
          console.log("INVALIDATE Certificate Status:" + CertificateStatus);
          if (CertificateStatus === "Valid") {
            const invalidateCertificateResponse = await axios.put(`/InvalidateCertificate/${ResourceId}`, {
              headers: {
                'Authorization': `Bearer ${bearerToken}`
              }
            });            
            // Certificate invalidated successfully, update the certificate list                  
          }            
          break;
        case "professional_certificate":
          break;
        case "default":
            // code block
          break;
      }
      //fetchCertificates(selectedUserId); 
    } catch (error) {
      console.error("Error invalidating certificate:", error);
    }
  }     

  const handleUpdateCertificate = async (event) => {
    event.preventDefault();
    const certificateId = selectedCertificateId;
    const newGrade = event.target.elements.grade2.value;
    
    try {
      await axios.put(`/BlockchainIntegration/newgrade/${certificateId}`, { newGrade }, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });    
      await invalidateResource("certificate", certificateId);      

    } catch (error) {
      console.error('Error updating certificate:', error);
    }
    
    fetchCertificates(selectedUserId);
    event.target.reset();
    
  };


  const handlePickerUniversity = async (e) => {
    //console.log("E", e.target.value);
    //setSelectedPickerUniversity(e.target.value);
    const response = await axios.get(`/UniversityPortal/UniversityID/${e.target.value}`, {
      headers: {
        'Authorization': `Bearer ${bearerToken}`
      }
    });
    const targetUniversityId = response.data;
    //console.log("TARGET UNI ID IS: ", targetUniversityId);
    fetchCoursesTarget(targetUniversityId);
  };

  const updateAcademicCertificateGrade = async (academicCertificateId) => {
    try {
      const response = await axios.put(`/academicCertificates/updateGrade/${academicCertificateId}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
  
      if (response.status === 200) {
        //fetchCertificates(selectedUserId);  
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error updating academic certificate grade:", error);
      return false;
    }
  };

  const deleteAcademicCertificate = async (certificateId) => {
    try {
      const response = await axios.delete(`/superservice/certificate/${certificateId}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
  
      if (response.status === 200) {
        fetchCertificates(selectedUserId);  
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error deleting academic certificate:", error);
      return false;
    }
  };
  
  const deleteExperienceCertificate = async (experienceCertificateId) => {
    try {
      //console.log("Deleting Experience Certificate with ID: " + experienceCertificateId);
      const response = await axios.delete(`/superservice/ExperienceCertificate/${experienceCertificateId}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
  
      if (response.status === 200) {
        fetchExperienceCertificates(selectedUserId);  
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error deleting Experience certificate:", error);
      return false;
    }
  };
  
  return (
    <div>
      <div className="container">
        <div className="frame">
          <div className="frame-content">
           {/*  <h1 className="subtitle-uni">Welcome back {universityName}!</h1>  */}
           <div className="logo-container">
              <img src={uni_logo} alt="Uni_Logo" className="uni_logo" />    
           </div>
          </div>
        </div>    
        <hr className="divider" />
      </div>
      <div className="section">
        <h1>List of Students</h1>
        <h1 className="description">Press a student to manage their academic and experience certificates.</h1>
        <table className="table">
          <thead>
            <tr>
              <th>YachaY ID</th>
              <th>Internal ID</th> 
              <th>Student Name</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => (
              <tr
                key={user.id}
                onClick={() => handleUserClick(user.id)}
                className={
                  selectedUserId === user.id ? "selected" : ""
                }
              >
                <td>{user.id}</td>
                <td>{user.internal_id}</td>
                <td>{user.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      
      {selectedUserId && (
        <div className="section">
          <hr className="divider" />
          <h1>List of Academic Certificates for selected Student</h1>
          <h1 className="description">Add a certificate to this Student. Choose the module to be associated with it.</h1>
          <div className="container">
            <div className="row">
              <div className="col">
                <label htmlFor="selectCourse" style={{ fontWeight: "bold" }}> Select Module:</label>
                <select
                  id="selectCourse"
                  value={selectedCourse}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                >
                  <option value="">-- Select Module --</option>
                  {courses?.map((course) => (
                    <option key={course.id} value={course.name}>
                      {course.name}
                    </option>
                  ))}
                </select>
              </div>
              
            <form className="form" onSubmit={(e) => handleInsertCertificate(e)}>
            <div className="col">
              <label htmlFor="inputGrade" style={{ fontWeight: "bold" }}>Select Grade:</label>
              <input type="number" name="grade" placeholder="Grade" />
            </div>              
          {/*   <div className="col">
              <label htmlFor="inputCredits" style={{ fontWeight: "bold" }}>Select Credits:</label>
              <input type="number" name="credits" placeholder="Credits" />
            </div>      
            <div className="col">
              <label htmlFor="inputHours" style={{ fontWeight: "bold" }}>Select Hours:</label>
              <input type="number" name="hours" placeholder="Hours" />
            </div>       */}
              <div className="row">
              <div className="col">
              <button type="submit">Certify Student</button>
              </div>
              </div>
              </form>                                 
            </div>
{/*             <button id="updateGradeButton" onClick={() => deleteAcademicCertificate(selectedCertificateId)}>Delete Selected Certificate</button>  */}
            {/* <button id="deleteCertificate" onClick={() => updateAcademicCertificateGrade(selectedCertificateId)}>Piratear/Mutar Grado</button>      */} 
          </div>
          {/* <button id="updateGradeButton">Hack/Mutate Grade</button> */}
             
          {certificates?.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
              {/*    <th>Internal ID</th> */}
                  <th>Associated Module ID</th> 
                  <th>Module Name</th>
                  <th>Grade</th>
                  <th>Credits</th>
                  <th>Hours of Duration</th>
                  <th>Creation Date</th>
      {/*             <th>Verification Status</th> */}
                </tr>
              </thead>
              <tbody>
                {certificates?.map((certificate) => (
                  <tr
                    key={certificate.ID_YachaY_Certificate}
                    onClick={() =>
                      handleCertificateClick(
                        universityId,
                        certificate.ID_YachaY_Certificate
                      )
                    }
                    className={
                      selectedCertificateId === certificate.ID_YachaY_Certificate
                        ? "selected"
                        : ""
                    }
                  >
                    <td>{certificate.ID_YachaY_Certificate}</td>
                  {/*   <td>{certificate.ID_Internal_Certificate}</td> */}
                    <td>{certificate.ID_YachaY_Course}</td> 
                    <td>{certificate.associatedCourseName}</td>
                    <td>{certificate.Grade}</td>
                    <td>{certificate.Credits}</td>
                    <td>{certificate.Hours}</td>                    
                    <td>{certificate.Date}</td>
                  {/* <td className={certificate.Status === 'Invalid' ? 'red-status' : 'green-status'}>{certificate.Status}</td>*/}
                  </tr>
                ))}
              </tbody>
            </table>
          )}          
        </div>
      )}   

{selectedUserId && (
        <div className="section">
          <hr className="divider" />
          <h1>List of Experience Certificates for selected Student</h1>
          <h1 className="description">Add an Experience Certificate to this Student.</h1>
          <div className="container">
            <div className="row">          
            <form className="form" onSubmit={(e) => handleInsertExperienceCertificate(e)}>
            <div className="col">
              <label htmlFor="inputDescription" style={{ fontWeight: "bold" }}>Description:</label>
              <input type="text" name="description" placeholder="Description" />
            </div>                     
              <div className="row">
              <div className="col">
              <button type="submit">Certify Student</button>
              </div>
              </div>
              </form>                                 
            </div>
            {/* <button id="deleteExperience" onClick={() => deleteExperienceCertificate(selectedExperienceCertificateId)}>Delete Selected Certificate</button> */} 
            {/* <button id="deleteExperience" onClick={() => deleteExperienceCertificate(selectedExperienceCertificateId)}>Delete Selected Certificate</button> */}
          </div>
          {experienceCertificates?.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                {/*  <th>Internal ID</th>       */}         
                  <th>Experience Description</th>
                  <th>Creation Date</th>
                  {/* <th>Verification Status</th> */}
                </tr>
              </thead>
              <tbody>
                {experienceCertificates?.map((experienceCertificate) => (                  
                  <tr key={experienceCertificate.ID_YachaY_Experience_Certificate}
                    onClick={() =>
                      handleExperienceCertificateClick(
                        universityId,
                        experienceCertificate.ID_YachaY_Experience_Certificate
                      )
                    }
                    className={
                      selectedExperienceCertificateId === experienceCertificate.ID_YachaY_Experience_Certificate
                        ? "selected"
                        : ""
                    }
                  >
                    <td>{experienceCertificate.ID_YachaY_Experience_Certificate}</td>
                    {/* <td>{experienceCertificate.ID_Internal_Certificate}</td>     */}         
                    <td>{experienceCertificate.Description}</td>
                    <td>{experienceCertificate.Date}</td>
                    {/* <td className={experienceCertificate.Status === 'Invalid' ? 'red-status' : 'green-status'}>{experienceCertificate.Status}</td> */}
                    
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}   
{/*Added Degrees */}
{selectedUserId && (
        <div className="section">
          <hr className="divider" />
          <h1>List of Degrees for selected Student</h1>
          <h1 className="description">Add a Degree to this Student.</h1>
          <div className="container">
          <div className="col">
                <label htmlFor="selectCourse" style={{ fontWeight: "bold" }}> Select Degree Type:</label>
                <select
                  id="selectDegreeType"
                  value={selectedDegreeType}
                  onChange={(e) => setSelectedDegreeType(e.target.value)}
                >
                  <option value="">-- Select Degree Type --</option>
                  {degreeTypes?.map((degreeType) => (
                    <option key={degreeType.code} value={degreeType.name}>
                      {degreeType.name}
                    </option>
                  ))}
                </select>
            </div>
            <div className="row">          
            <form className="form" onSubmit={(e) => handleInsertDegreeCertificate(e)}>
            <div className="col">
              <label htmlFor="inputDescription" style={{ fontWeight: "bold" }}>Name:</label>
              <input type="text" name="name" placeholder="Name" />
            </div>                     
            <div className="col">
              <label htmlFor="inputGrade" style={{ fontWeight: "bold" }}>Select Grade:</label>
              <input type="number" name="grade" placeholder="Grade" />
            </div>  
              <div className="row">
              <div className="col">
              <button type="submit">Certify Student</button>
              </div>
              </div>
              </form>                                 
            </div>
         </div>
          {degreeCertificates?.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>             
                  <th>Type</th>      
                  <th>Name</th>
                  <th>Grade</th>      
                  <th>Creation Date</th>             
                </tr>
              </thead>
              <tbody>
                {degreeCertificates?.map((degreeCertificate) => (                  
                  <tr key={degreeCertificate.ID_YachaY_Degree_Certificate}
                    onClick={() =>
                      handleDegreeCertificateClick(
                        universityId,
                        degreeCertificate.ID_YachaY_Degree_Certificate
                      )
                    }
                    className={
                      selectedDegreeCertificateId === degreeCertificate.ID_YachaY_Degree_Certificate
                        ? "selected"
                        : ""
                    }
                  >
                    <td>{degreeCertificate.ID_YachaY_Degree_Certificate}</td>                  
                    <td>{degreeCertificate.DegreeType}</td>
                    <td>{degreeCertificate.Name}</td>
                    <td>{degreeCertificate.Grade}</td>
                    <td>{degreeCertificate.Date}</td>                    
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

const UniversityLocal_Certification = () => {

 
  const targetUniversityId = Number(University_ID); //[CONFIGURATION]
  const [selectedPickerUniversity, setSelectedPickerUniversity] = useState(targetUniversityId);
  
  const [universities, setUniversities] = useState([]);
  const [bearerToken, setBearerToken] = useState("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMSIsInVzZXJfZW1haWwiOiJ1c2VyMUBnbWFpbC5jb20iLCJjb21wYW55X2lkIjoxfQ.hV1MQI4OKRguWsd11uVqWzaAz4h9v0zv_33U-ioKza8");
  


  useEffect(() => {    
    fetchAvailableUniversities();
  }, []);
  
  const fetchAvailableUniversities = async () => {
    try {
      const response = await axios.get("/list-universities", {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
      if (response.data.length === 0) {
        console.log("No universities found");
        setUniversities([]);
        return;
      }
      else {        
        console.log("Available Universities", response.data);   
        setUniversities(response.data);  
      }
    } catch (error) {
      console.error("Error fetching available universities:", error);
    }
  };
  

  return (
    <div className="university-portal">
{/*       <label htmlFor="universityPicker">Select University:</label>
            <select
              id="universityPicker"
              value={selectedPickerUniversity}
              onChange={(e) => setSelectedPickerUniversity(e.target.value)}>
              <option value="">-Select University-</option> 
              {universities?.map((university) => (
                <option key={university[0]} value={university[0]}>
                  {university[1]}
                </option>
              ))}
            </select>
       */}
      <AcademicCertificationContainer universityId={selectedPickerUniversity} />     
    </div>
  );
};

export default UniversityLocal_Certification;
