//# YachaY Project - University Local Interface Front-End
//Last Updated: 18/01/2024

import React, { useState, useEffect } from 'react';
import logo from './images/logo.png'; //Configure here to change the YachaY system logo
import './App.css';

import UniversityLocal_Certification from './UniversityLocal_Certification';
import axios from 'axios';
import {UrlBackend} from './Url';
import {UrlMahara} from './Url';

//Commit not happening?
function App() { 
  //https://portal.yachay.grisenergia.pt/
  const connection_type = 'remote'; //local or remote
  //const connection_type = 'remote'; //local or remote
  if(connection_type === 'local'){
    //When in local should use the default baseURL
    axios.defaults.baseURL = 'http://localhost:4000';
  }else if(connection_type === 'remote'){
    //Setting up axios to use the remote server
    axios.defaults.baseURL = UrlBackend;
  }
  console.log("App.js - connection_type: " + connection_type + "|| axios_baseURL:" + axios.defaults.baseURL);


  
  return (
    <div className="container">      
    <div className="opening_container">         
        <div className="header">
          <div className="title-container">    
          <div><h1 className="title">YachaY Local Interface </h1></div>        
          </div>
          <div className="image-container">
            <img src={logo} alt="Logo" className="logo" />            
          </div>
        </div>
      </div>
      <UniversityLocal_Certification/>    
    </div>    
  );  
}

export default App;

 
